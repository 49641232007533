.nav_bar{
  /* background-color:transparent; */
  box-shadow: none;
  font-weight: bold;
}
nav.navbar.nav_bar{
  /* background: linear-gradient(to right, #ffff00,#ff9600); */
  background-color:transparent;
  background-size:100vw;
  background-repeat:no-repeat;
  min-height:6vh;
  z-index: 1001;
}
nav.navbar.nav_bar.nav_below{
  transition: all .5s ease-in;
  background-color: var(--dark-contrast);
  /* background: #ccc; */
  box-shadow: 1px 1px 30px rgba(0,0,0,.3);
}

/* nav.navbar.nav_bar .menu_toggle path {
  stroke: var(--light-n12)
}*/

.menu_toggle path {
  mix-blend-mode: difference;
}

.menu_toggle {
  z-index: 1051;
}

/* nav.navbar.platf_navb{
  background-color: var(--dark-contrast);
} */
.logo{
  min-width:30px;
  width:3vw;
}
/* layer from the logo */
.nav_below .cls-16{
  fill:var(--light-n12);
  /* mix-blend-mode: difference; */
}

.nav.navbar .nav-item{
  mix-blend-mode:difference;
  /* color:#fff; */
}
.nav-link{
  color:var(--dark-n12);
  transition: all .2s ease;
}
.nav_below .nav-link{
  color:var(--light-n12);
}
.nav-link.selected{
  border-bottom:2px solid #fff;
}
.toggle-icon-white {
  color:white;
}

/* circle in menu */
.circle_menu{
  position:fixed;
  top:0;
  padding-top:130px;
  padding-bottom:5rem;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1000;
  right:0;
  width:35vw;
  height:100vh;
  background-color: var(--dark-contrast);
  /* background-color:rgba(255,255,255,.8); */
  filter:blur(0);
  box-shadow: 2px 0 10px rgba(0,0,0,.3);
  z-index: -1;
}
.front_menu{
  z-index: 1000;
}
/* .circle_in{
  background-color: #fff;
  animation: circlein .3s ease-in forwards;
}
.circle_out{
  background-color: #fff;
  animation: circleout .3s ease-in forwards;
}
@keyframes circlein{
  0%{
    transform: translateZ(0) translateX(100%);
  }
  1%{
    transform: translateZ(0) translateX(0);
    clip-path: circle(0% at 92% 6%);
  }
  100%{
    clip-path: circle(150% at 92% 6%);
    transform: translateZ(0) translateX(0);
  }
}
@keyframes circleout{
  0%{
    transform: translateZ(0) translateX(0);
    clip-path: circle(150% at 92% 6%);
  }
  99%{
    transform: translateZ(0) translateX(0);
    clip-path: circle(0% at 92% 6%);
  }
  100%{
    transform: translateZ(0) translateX(100%);
  }
} */
@media (max-width:768px ){
  nav.navbar.nav_bar{
      background-size:cover;
  }
  .logo{
    min-width:35px;
    width:5vw;
  }
  .circle_menu{
    width:100vw;
  }
  .circle_out{
    position:fixed;
    padding-top:6rem;
    top:0;
    width:100vw;
    height:100vh;
    /* background-color:rgba(255,255,255,.8); */
    transition:all .3s ease-in;
    box-shadow: 2px 0 10px rgba(0,0,0,.3);
  }
}
