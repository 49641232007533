:root{
  --dark-n12:#3c3c3c;
  --light-n12:#f2f2f2;
  --secondary-font:p22-underground-sc, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  --main-font:nunito, sans-serif;
  --gray-contrast:#e6e6e6;
  --secondary-color:#e4412d;
  --secondary-tone-1:#faa236;
  --secondary-tone-2:#f58931;
  --main-color:#28ab4a;
  --dark-contrast:#302f3d;
  --blue-dark:#486478;
  --grad-blue-1:#3136a9;
  --grad-blue-2:#319ee4;
  --grad-purple-1:#493A84;
  --grad-purple-2:#7654A2;
  --primary-button:#F29F00;
  --primary-button-alt:#FF9300;
  /* --primary:; */
}

html{
  scroll-behavior: smooth;
}

body{
  background:var(--light);

}


.public_pages{
  background: var(--soft-gray);
  background-image: linear-gradient(to top, var(--grad-purple-1), var(--grad-purple-2));
}

.App {
  text-align: center;
  font-family: nunito,sans-serif;
  font-weight: 300;
  font-style: normal;

}
.wrapper {
  min-width:320px;
  max-width:100vw;
  width:100vw;
  overflow-x: hidden;
}

.row{
   flex-shrink: 0;
}
.multiline{
  white-space: pre-line;
}

/* generales */

/* primary button */

.btn-primary{
  background:var(--primary-button);
  border-color: var(--primary-button);
  color:#fff;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:not(:disabled):not(.disabled):active{
  background: var(--primary-button-alt);
  border-color: var(--primary-button-alt);
}

.btn-primary.disabled, .btn-primary:disabled {
  background: #ce826e;
  border-color: #ce826e;
}

/* secondary outline button */

/* .btn-outline-secondary {
  border-color: var(--light);
  color:var(--light);
}

.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show>.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: var(--light);
    border-color: var(--light);
}

.btn-outline-secondary:hover,
.btn-outline-secondary:focus,
.btn-outline-secondary:active,
.btn-outline-secondary:not(:disabled):not(.disabled):active{
  background: #fff;
  border-color: #fff;
} */

.sect_30{
  min-height: 30vh;
}

.sect_80{
  min-height: 80vh;
}

.Platform{

  position:fixed;
  top:0;
  left:0;
  bottom:0;
  right:0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.abs_cont{
  position: relative;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* animations */

/*Entering*/
.fadeTranslate-enter {
  opacity: 0;
  transform: translate(-3vw, 0);
  position: fixed;
}

.fadeTranslate-enter.fadeTranslate-enter-active {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 500ms ease-in;
}

.fadeTranslate-exit {
  opacity: 1;
  position: fixed;
  transform: translate(0, 0);
}

.fadeTranslate-exit.fadeTranslate-exit-active {
  opacity: 0;
  transform: translate(3vw, 0);
  transition: all 400ms ease-in;
}

/**Fade in place**/
@keyframes fadeInPlace {
  from {
    opacity: 0;
    transform: translate3d(0, 3vh, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInPlace {
  animation-name: fadeInPlace;
}
