.ghost_card{
    animation: placeholderSkeleton 1.5s infinite;
    background: #f6f7f8;
    /* background-image: linear-gradient(to left, #f6f7f8, #edeef1); */
    background-repeat: no-repeat;
    background-size: cover;
    opacity: .5;
    /* height: 100%; */
    /* position: relative; */
}

@keyframes placeholderSkeleton {
  0% {
      opacity: .3;
    }

    50% {
      opacity: .8;
    }

    100% {
      opacity: .3;
    }
}
