.front_hero{
  z-index: 5;
}
.gradBlue{
  background-image: linear-gradient(to left, var(--grad-blue-1),var(--grad-blue-2) );
}

.gradPurple{
  background-image: linear-gradient(to left, var(--grad-purple-1),var(--grad-purple-2) );
}

.bodymovin.hero {
  position:absolute;
  min-height:600px;
  min-width: 320px;
  width:100%;
  height:100%;
  z-index: 1;
}

.float_hero_right{
  position:absolute;
  background-size:auto 100%;
  background-repeat:no-repeat;
  overflow: hidden;
  right:0;
  top:0;
  width:45vw;
  height:auto;
}
.float_hero_top{
  position:absolute;
  background-size:auto 100%;
  background-repeat:no-repeat;
  overflow: hidden;
  right:0;
  top:0;
  width:100vw;
  height:auto;
}
.hero_overlay{
  position:absolute;
  top:0;
  width:100%;
  height:auto;
  background-size: cover;
}
.jumbo_sections{
  position:relative;
  min-height: 60vh;
  background-size: cover;
}
.jumbo_sections:after{
  position:absolute;
  content: '';
  left: 0;
  right: 0;
  top: 85%;
  bottom: auto;
  height:30%;
  transform: skewY(-5deg);
  background:var(--light);
}
.jumbo_hero{
  position:relative;
  min-height: 105vh;
  background-size: cover;
}
.jumbo_hero:after{
  position:absolute;
  content: '';
  left: 0;
  right: 0;
  top: 88%;
  bottom: auto;
  height:30%;
  transform: skewY(-5deg);
  background:var(--light);
}
.hero_bottom{
  position:absolute;
  top:560px;
  width:100%;
  height:auto;
  /* background-size: cover; */
}
.hero_bg{
  position:absolute;
  top:0;
  background-size:100% auto;
  background-repeat:no-repeat;
  height:561px;
  min-width:100vw;
}
.hero_container{
  display:flex;
  position:absolute;
  top:0;
  z-index: 1;
  width:100vw;
}

@media (max-width:768px ){
  .hero_container{
    top:50px;
  }
  .jumbo {
    min-height: 600px;
    background-position: 50% 50%;
  }
  .hero_bg{
    height: 100vh;
  }
  .hero_overlay{
    width:150%;
    overflow-x: hidden;
  }
  .morra{
    max-height: 350px;
  }

}
