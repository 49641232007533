.name_title {
  font-family: fira-sans, sans-serif;
  font-weight: bold;
  font-size: 32pt;
  font-style: normal;
}
.prof_img_cont {
  min-width: 250px;
  min-height: 250px;
}
.profile_img {
  max-width: 350px;
  max-height: 350px;
  min-width: 250px;
  min-height: 250px;
}

.decorative_header_img {
  max-width: 300px;
  object-fit: cover;
}

.company_icon {
  position: absolute;
  right: 0;
  bottom: 0;
  /* transform: translateY(100%); */
  width: 35%;
}

.verified_icon {
  position: absolute;
  right: 0;
  bottom: 5%;
}

.qr_code_card {
  max-width: 30vw;
}

.info_cont {
  /* max-height: 90vh; */
  min-height: 80vh;
}
.profile_img_cont {
  max-height: 90vh;
}

.logo_inline {
  max-height: 50px;
}

.bg_float {
  position: absolute;
  background-size: auto 100%;
  background-repeat: no-repeat;
  overflow: hidden;
  right: 0;
  top: 0;
  width: 45vw;
  height: 100vh;
}

/* @media(max-width:1200px){
  .company_icon{
    position: absolute;
    right:15%;
    transform: translateY(70%);
    width:25%
  }
} */

@media (max-width: 768px) {
  .profile_img {
    max-width: 40vw;
    max-height: 40vw;
  }
  /* .company_icon{
    position: absolute;
    right:15%;
    transform: translateY(80%);
    width:30%
  } */
  .info_cont {
    min-height: 50vh;
  }
  .profile_img_cont {
    max-height: 40vh;
  }
  .name_title {
    font-size: 24pt;
  }
  .job_title {
    font-size: 18pt;
  }
  .bg_float {
    width: 120vw;
    height: 60vh;
    top: -12%;
  }
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
  }
}
