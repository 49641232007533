.close_bagde{
  line-height: 17pt;
}


/*Animations for CSSTransitionGroup  */

.question-enter {
  opacity: 0.01;
  transform: translateY(50%);
}
.question-enter-active {
  opacity: 1;
  transform: translateY(0%);
  transition: all 500ms ease-out;
}
.question-exit {
  opacity: 1;
  transform: translateY(0%);
}
.question-exit-active {
  opacity: 0.01;
  transform: translateY(50%);
  transition: all 500ms ease-out;
}
.message-appear {
  opacity: 0.01;
  transform: translateY(20%);
}
.message-appear-active {
  opacity: 1;
  transform: translateY(0%);
  transition: all 600ms ease-out;
}
.message-exit {
  opacity: 1;
  transform: translateY(0%);
}
.message-exit-active {
  opacity: 0.01;
  transform: translateY(20%);
  transition: all 600ms ease-out;
}
.mainQuestions{
  min-height: 15vh;
}


/*Custom input*/
.custom-file-label:before{
  content:"Navegar"
}
.custom-file-label:after{
  content:"Seleccionar archivos..."
}

.custom-file-label.hover {
  box-shadow: 0 0 0 .2rem rgba(11,210,255,.5);
}
.custom-file .custom-file-label.got-file:after{
  content:"Archivo cargado."
}
.custom-file .custom-file-label.got-files:before{
  content:"Cambiar"
}
.custom-file .custom-file-label.got-files:after{
  content:"Archivos cargados."
}
