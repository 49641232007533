.roster_profile_img {
  min-width: 150px;
  min-height: 150px;
}

.becard_roster {
    max-width: 25%;
  }

@media (max-width: 1200px) {
  .becard_roster {
    max-width: 33%;
  }
}

@media (max-width: 576px) {
  .becard_roster {
    max-width: 100%;
  }
}
