.secondary_title {
  color: var(--secondary-tone-1);
}

.float_intro_left {
  position: absolute;
  left: 0;
  top: 30%;
  width: 35vw;
}

.float_action_left {
  position: absolute;
  left: 0;
  margin-left: -10%;
  width: 25vw;
}

.progr_circle circle {
  stroke: var(--info);
  fill: transparent;
  transition: stroke-dashoffset 0.1s ease;
}

.feature_menu_item_style {
  transition: all 0.2s ease;
}
.feature_menu_item_style.active {
  background: rgb(242, 158, 0);
  background: linear-gradient(
    0deg,
    rgba(242, 158, 0, 1) 34%,
    rgba(242, 107, 84, 1) 100%
  );
  border-radius: 1.5rem;
}
.feature_menu_item {
  transition: all 0.2s ease;
}

.feature_menu_item.active {
  background: white;
  border-radius: 0.25rem;
}
.sect_title {
  font-size: 30pt;
}
.feat_cont {
  min-height: 100vh;
}
.color_sect {
  background-color: var(--bsl-purlple-dark);
}
.feature_img {
  position: absolute;
}

.color_sect .link_how {
  color: #fff;
}
.color_sect .nav-link.active {
  color: #3c3c3c;
}
/* use cases section */
.usecases_cont {
  overflow-x: hidden;
  min-height: 140vh;
}
.card_placeholder {
  opacity: 0;
}

.card_cust_overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.img_holder_cust_card {
  position: relative;
}
.img_holder_cust_card:after {
  content: "";
  background: var(--light);
  position: absolute;
  transform: skewX(5deg);
  margin-right: -5%;
  right: 0;
  top: 0;
  bottom: 0;
  width: 10%;
}

.customer_story_card img {
  height: 100%;
  /* background-size: 50%; */
  max-height: 35vh;
  object-fit: contain;
}

.customer_story_text {
  position: absolute;
  top: 0;
  z-index: 1;
  min-height: 50vh;
  width: 100%;
}

.cust_text_holder {
  background: rgba(0, 0, 0, 0.5);
}

.customer_color {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: calc(0.25rem - 1px);
}

.control_client_prev,
.control_client_next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  opacity: 0.75;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
  cursor: pointer;
  background: transparent;
  border: none;
}
.control_client_prev:hover,
.control_client_next:hover {
  opacity: 1;
}

.control_client_prev {
  left: 0;
  margin-left: -10%;
}
.control_client_next {
  right: 0;
  margin-right: -10%;
}

.usecase_img_prev,
.usecase_img_next {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 35%;
}

.usecase_img_prev {
  left: 0;
  margin-left: -25%;
}
.usecase_img_next {
  right: 0;
  margin-right: -25%;
}

.placeholder {
  overflow-y: hidden;
}
.img_darkov {
  position: absolute;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.2)
  );
  width: 100%;
  height: 100%;
  /* z-index: 2; */
}
.card hr {
  border: 1px solid #ea5b4c;
  width: 100%;
}
.title {
  font-family: bebas-neue, sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-top: 6rem;
  font-size: 18pt;
  letter-spacing: 18pt;
}

.plan_title {
  font-size: 32pt;
  font-weight: 700;
}

.card_plans.bg_orange {
  background: var(--bsl--compl-orange);
}
.card_plans.bg_red {
  background: var(--bsl--compl-blue);
}
.card_plans.bg_purple {
  background: var(--bsl--compl-purple);
}

.subs_land_cont {
  background-image: linear-gradient(to left, var(--bsl-purlple-dark), #6d0ff2);
  margin-bottom: -10vh;
  z-index: 2;
}

@media (max-width: 768px) {
  .title {
    font-size: 10pt;
    letter-spacing: 12pt;
  }
  .display-3 {
    font-size: 24pt;
  }
}
