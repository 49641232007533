/*---------------------------CalendarioSmall--------------------*/
.calendar_small{
  font-size: 10pt;
  margin-top:-40px;
}

/* .activities{
  margin-top: 2rem;
} */

/* .cont_cal{
  width:100%;
}
.navMonth{
  width:auto;
} */
/* .navMonth .navCal{
  width:1.5rem;
  height:1.5rem;
  margin:1rem;
} */
.navMonth .monthIndicator{
  flex-grow: 3;
}
/* .calMonth{ */
  /* padding:1rem */
/* } */
.dayName{
  width:25px;
  font-weight: bold;
  max-width: 25px;
  min-width: 25px;
}
/* .daysCont{

} */
.daysContRow{
  position:relative;
}
.daysCont .dayCal {
  width:25px;
  height:25px;
  max-width: 25px;
  max-height: 25px;
  flex:1;
  border-bottom:2px solid transparent;
  cursor: pointer;
}
/* .daysCont .dayCal:hover {

} */
.dayCal.today{
  border:1px solid #17a2b8;
  border-radius:.25rem;
}
.dayCal.activ{
  border-bottom:2px solid #007bff;
}
.dayCal.selDay{
  background-color:#17a2b8;
}
.dayCal.notMonth{
  opacity:.4;
}
.slidein-enter {
  opacity: 0;
  transform:translate(35vw,0);
}

.slidein-enter.slidein-enter-active {
  opacity: 1;
  transform:translate(0,0);
  transition: all 500ms ease-in;
}

.slidein-exit {
  opacity: 1;
  transform:translate(0,0);
}

.slidein-exit.slidein-exit-active {
  opacity: 0;
  transform:translate(-35vw,0);
  transition: all 500ms ease-in;
}

@media (max-width:768px) {
  .calendar_small{
    margin-top: auto;
  }
}
