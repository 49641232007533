.slideout {
  position:fixed;
  /* top:1.5rem; */
  overflow-y: scroll!important;
  overflow-x: hidden;
  z-index: 1000;
  right:0;
  width:50vw;
  height:100vh;
  padding-bottom: 5rem;
  padding-top: 2rem;
  background-color:#efefef;
  transform:translateX(100%);
  transition:all .5s ease-in;
  box-shadow: 2px 0 10px rgba(0,0,0,.3);
}
.slidein {
  transform:translateX(0);
}

.slideoutleft {
  position:fixed;
  top:1.5rem;
  overflow-y: scroll!important;
  overflow-x: hidden;
  z-index: 1000;
  left:0;
  width:25vw;
  height:100vh;
  padding-bottom: 5rem;
  background-color:#efefef;
  transform:translateX(-100%);
  transition:all .5s ease-in;
  box-shadow: 1px 0 10px rgba(0,0,0,.3);
}
.slideinleft {
  transform:translateX(0);
}

@media (max-width:576px ){
  .slidein{
    width:100vw;
  }
}
