.profile_card{
  margin-top: -4rem;
  position: inherit;
  z-index: 1 !important;
}

.profile_picture{
  position: inherit;

}

.overlapping_profile_img img{
  max-width: 300px;
  max-height: 300px;
  min-width: 200px;
  min-height: 200px;
  z-index: 2 !important;
}
