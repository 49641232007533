.img_circular {
  min-width: 200px;
  min-height: 200px;
}

.side_Bar {
  background-color: var(--dark-contrast);
  /* min-height: 100vh; */
  height: 100vh;
  min-width: 95px;
  overflow-y: scroll;
}

.ad_img {
  object-fit: contain;
}

.secondary_bar {
  background-color: #11506c;
  height: 100vh;
  /* min-height: calc(100vh - 50px); */
  padding-top: 60px;
  /* margin-top: 50px; */
  min-width: 255px;
  overflow-y: scroll;
}

.content_col {
  height: 100vh;
  /* max-height: calc(100vh - 50px); */
  overflow-y: scroll;
}

.card.selected {
  box-shadow: 0 0 0 0.2rem rgba(11, 210, 255, 0.5);
}

.list-group-item.selected {
  border-bottom: 5px solid rgba(11, 210, 255, 0.5);
}

.blue_grad_bg {
  background-image: linear-gradient(
    to right,
    var(--grad-blue-1),
    var(--grad-blue-2)
  );
}

/* .ad_sec_bar{
  background-image:linear-gradient();
} */

@media (max-width: 768px) {
  .side_Bar {
    /* background-color:#343a40; */
    /* min-height: 10px; */
    min-height: 50px;
    height: auto;
    /* height: 50px; */
    margin-top: 60px;
    min-width: 95px;
  }
  .img_circular {
    min-width: 150px;
    min-height: 150px;
  }
  /* .secondary_bar{
    background-color:#11506C;
    min-height: 10vh;
    margin-top: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
  } */
  .content_col {
    max-height: calc(100vh - 100px);
    padding-bottom: 1rem;
  }
}
